import Table from 'react-bootstrap/Table';
import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import FacebookIcon from '@mui/icons-material/Facebook';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function Footer() {
    return (
        <>
            <Box sx={{
                bgcolor: 'maroon',
                display: 'grid',
                gridTemplateColumns: { md: 'repeat(3, 1fr)' },
                gap: 2,
                color: '#eee', p: 2
            }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <h4>Contact Us</h4>
                        <HomeIcon fontSize="small" /> 5153 Bowen Drive Deerfield Ohio 45040<br />
                        <CallIcon fontSize="small" /> (513) 271-8424
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <h4>Hours</h4>
                        <Table bordered style={{ color: '#eee', textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Lunch</th>
                                    <th>Dinner</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sunday</td>
                                    <td colSpan={2}>CLOSED</td>
                                </tr>
                                <tr>
                                    <td>Monday - Friday</td>
                                    <td>11:00am - 2:30pm</td>
                                    <td>5:00pm - 8:30pm</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td colSpan={2}>4:00pm - 8:30pm</td>
                                </tr>
                            </tbody>
                        </Table>
                        <span style={{fontSize:11}}>* Dine in seating closes at 2pm for lunch and 8pm for dinner. However, we may close early at our discretion due to high business volume. We apologize for any inconvenience.</span>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <h4>Follow us</h4>
                        <a href="https://www.facebook.com/thaispicymason/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#eee' }}>
                            <FacebookIcon />
                        </a>
                    </Grid>
                </Grid>
            </Box><p className="text-center" style={{ backgroundColor: '#333', color: '#eee' }}>&copy; {new Date().getFullYear()} - Thai Spicy</p></>
  );
}

export default Footer;