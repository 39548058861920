import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './shared/Header';
import Home from './screens/Home';
import Menu from './screens/Menu';

function App() {
  return (
    <>
    <Router>
      <Header />   
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
