import React from 'react';
import Footer from '../shared/Footer';
import Config from '../config';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import CustomImage from '../components/CustomImage';

const Menu=()=>{
    const grp = localStorage.getItem('group');
    const [value, setValue] = React.useState(grp?grp:Config.groups[0]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        localStorage.setItem('group', newValue);
    };
    const toLC=(val)=>{
        return val.toLowerCase().replaceAll(' ', '').replaceAll('&', 'n').replaceAll('/', '');
    }

    return (
        <><Box className="main-content">
            <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} variant='scrollable' scrollButtons allowScrollButtonsMobile>
                    {Config.groups.map(g => (
                        <Tab key={g} label={g} value={g} />
                    ))}
                </Tabs>
            </Box>
            <Box sx={{
                p: 2,
                bgcolor: 'background.default',
                display: 'grid',
                gridTemplateColumns: { md: 'repeat(3, 1fr)' },
                gap: 2,
                m: 2
            }}>
                {Config[`${toLC(value)}`].map(v => (
                    <Grid key={v.name} container spacing={2}>
                        <Grid item>
                            <ButtonBase sx={{ width: 68, height: 68 }}>
                                <CustomImage src={`${toLC(value)}/${toLC(v.name)}.jpeg`} alt={v.name} width='100%' />
                            </ButtonBase>
                        </Grid>
                        <Grid item xs>
                            <Typography style={{ textAlign: 'right', fontWeight: 'bolder', fontSize: 10, color: '#800000' }}>
                                {v.price && `$${v.price}`}{v.price && v.dinnerPrice && "|"}{v.dinnerPrice && `$${v.dinnerPrice}`}
                            </Typography>
                            <Typography>{v.name}{v.notes ? ` (${v.notes})` : null}</Typography>
                            <Typography variant='caption'>{v.description ? v.description : null}</Typography>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Box>
        <Box><Footer /></Box></>
    )
}
export default Menu;