import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/logo.svg'
function Header() {
  return (
    <Navbar bg="dark" expand="sm" collapseOnSelect variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              height="40"
              className="d-inline-block align-top"
              alt="ThaiSpicy"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/menu">Menu</Nav.Link>
            <Nav.Link href="https://thaispicy.foodorder.site/rs/menu_home.action?resInput=RES703" target="_blank" rel="noreferrer" style={{ backgroundColor: "#fff", color: "green", border: "1px solid red", fontWeight: "bolder", borderRadius: 5, textAlign: "center" }}>Order Online</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default Header;