const groups = [
    "Appetizers", "Soups", "Salads & Spicy Salads", "Fried Rice", "Noodle", "Noodle Soup", "Wok Entree", "Curry", 
    "Specialties", "Dessert", "Beverages",
]

const appetizers=[
    {"name":"Gyoza", "price":8.99, "description": "Homemade Pan-fried Pork or Vegetable dumpling and served with homemade soy- sesame sauce", "notes":"5 pc."},
    {"name":"Chicken Satay", "price":10.99, "description": "marinated chicken skewer served with peanut sauce and side of cucumber salad", "notes":"4 pc."},
    {"name":"Spring Roll", "price":5.99, "description": "Crispy vegetarian roll served with sweet chili dipping", "notes":"2 pc."},
    {"name":"Crab Rangoon", "price":8.99, "description": "Crispy wonton stuffed with cream cheese, crabstick, carrot, and green onion served with sweet plum sauce", "notes":"5 pc."},
    {"name":"Shrimp Tempura", "price":9.99, "description": "Panko battered shrimp deep fried served with tempura sauce", "notes":"4 pc."},
    {"name":"Fried Tofu", "price":5.99, "description": "Deep fried bean curd tofu served with sweet chili sauce and crushed peanut", "notes":"5 pc."},
    {"name":"Edamame", "price":5.99, "description": "Steamed soy beans sprinkled with salt", "notes":""},
    // {"name":"Fried Calamari", "price":8.95, "description": "Tempura squid served with tempura sauce", "notes":""},
    // {"name":"Thai Pork Dumpling", "price":8.95, "description": "Steamed pork dumpling sprinkled with fried garlic served with soy-sesame sauce", "notes":""},
]
const noodle=[
    {"name":"Bangkok Noodle", "price":11.95, "dinnerPrice":15.95, "description": "Stir-fried wide rice noodle with your choice of meat, onion, carrot, tomato, scallion, and cilantro on bed of lettuce. Served with chili sauce", "notes":""},
    {"name":"Crispy Pad Thai", "price":11.95, "dinnerPrice":15.95, "description": "Stir-fried crispy noodle with your choice of meat, bean sprout, scallion, egg, and crushed peanut", "notes":""},
    {"name":"Drunken Noodle", "price":11.95, "dinnerPrice":15.95, "description": "Stir fried wide rice noodle with your choice of meat, mushroom, bell pepper, onion, carrot and basil leaf", "notes":""},
    {"name":"Pad See Ewe", "price":11.95, "dinnerPrice":15.95, "description": "Stir- fried wide rice noodle with your choice of meat, egg, broccoli, and cabbage and carrot", "notes":""},
    {"name":"Pad Thai", "price":11.95, "dinnerPrice":15.95, "description": "Stir-fried rice noodle with your choice of meat, egg, scallion, bean sprout, and crushed peanut", "notes":""},
    {"name":"Pad Woon Sen", "price":11.95, "dinnerPrice":15.95, "description": "Stir fried Bean thread noodle with your choice of meat, egg, baby corn, cabbage, celery, carrot, scallion and snow pea", "notes":""},
    {"name":"Rad Na", "price":null, "dinnerPrice":15.95, "description": "Wide rice noodles stir fried in dark soy sauce, assorted vegetable, and covered with a thick, savory sauce with your choice of meat", "notes":""},
]
const noodlesoup=[
    {"name":"Guay Tiew Tom Yum", "price":null, "dinnerPrice":15.95, "description": "Spicy noodle soup with minced pork, meat ball, bean sprout, cilantro, crushed peanut and lime juice", "notes":""},
    {"name":"Thai Noodle Soup", "price":11.95, "dinnerPrice":15.95, "description": "Rice noodle, fish balls, bean sprout, cilantro, scallion, and served in delicious broth", "notes":""},
    {"name":"Tom yum Ramen", "price":null, "dinnerPrice":15.95, "description": "The famous Thai hot and sour soup with ramen noodle, mushroom, cilantro, and tomato", "notes":""},
]
const wokentree=[
    {"name":"Assorted Vegetables", "price":11.95, "dinnerPrice":15.95, "description": "Sautéed assorted vegetables in a light brown sauce and your choice of meat", "notes":""},
    {"name":"Cashew Nut", "price":11.95, "dinnerPrice":15.95, "description": "Stir fried onion, scallion, carrot, zucchini, bell pepper, and cashew nut in roasted chili sauce with your choice of meat", "notes":""},
    {"name":"Fresh Ginger", "price":11.95, "dinnerPrice":15.95, "description": "Stir fried fresh ginger with mushroom, bell pepper, onion, celery, baby corn, carrot, and scallion in ginger sauce with your choice of meat", "notes":""},
    {"name":"Hot Basil", "price":11.95, "dinnerPrice":15.95, "description": "Thai traditional Basil stir fried with choice of ground Chicken or Pork, bell pepper, carrot, green bean, basil leaf in chili basil sauce", "notes":""},
    {"name":"Pepper Onion", "price":11.95, "dinnerPrice":15.95, "description": "Stir fried bell pepper and onion in brown garlic sauce with your choice of meat", "notes":""},
    {"name":"Spicy Eggplant", "price":11.95, "dinnerPrice":15.95, "description": "Sautéed Eggplant, carrot, and bell pepper in chili basil sauce with your choice of meat", "notes":""},
    {"name":"Sweet & Sour", "price":11.95, "dinnerPrice":15.95, "description": "Stir fried onion, tomato, cucumber, carrot, scallion, and pineapple in sweet and sour sauce with your choice of meat", "notes":""},
    {"name":"Teriyaki", "price":11.95, "dinnerPrice":15.95, "description": "Your choice of meat with teriyaki sauce and steamed vegetable", "notes":""},
    {"name":"Thai Spicy", "price":11.95, "dinnerPrice":15.95, "description": "Stir fried broccoli, carrot, bell pepper, baby corn, bamboo, and cabbage in red curry paste with your choice of meat", "notes":""},
]
const friedrice=[
    {"name":"Crab Meat Fried Rice", "price":null, "dinnerPrice":18.95, "description": "Fried rice with egg, crab meat, carrot, pea, scallions and cilantro", "notes":""},
    {"name":"Pineapple Fried Rice", "price":11.95, "dinnerPrice":15.95, "description": "Fried rice with your choice of meat, pineapple, egg, onion, pea, carrots, scallion, raisin, cashew nut with curry powder", "notes":""},
    {"name":"Spicy Basil Fried Rice", "price":11.95, "dinnerPrice":15.95, "description": "Fried rice with your choice of meat, bell pepper, onion, carrot, and basil leaf in chili garlic sauce", "notes":""},
    {"name":"Thai Fried Rice", "price":11.95, "dinnerPrice":15.95, "description": "Fried rice with your choice of meat, egg, green pea, onion, carrot, broccoli, scallion, and tomato", "notes":""},
]
const curry=[
    {"name":"Green Curry", "price":11.95, "dinnerPrice":15.95, "description": "Eggplant, bamboo shoot, green bean, bell pepper, carrot and basil leaf in coconut green curry sauce with your choice of meat", "notes":""},
    {"name":"Mango Curry", "price":11.95, "dinnerPrice":15.95, "description": "Mango, pineapple, onion, and carrot in yellow curry sauce with your choice of meat", "notes":""},
    {"name":"Massaman Curry", "price":11.95, "dinnerPrice":15.95, "description": "Potato, onion, carrot, and roasted peanut in coconut curry sauce with your choice of meat", "notes":""},
    {"name":"Panang Curry", "price":11.95, "dinnerPrice":15.95, "description": "A light red, slightly sweeter curry paste with kaffir lime leaf and coconut milk, with carrot, green bean, and your choice of meat", "notes":""},
    {"name":"Red Curry", "price":11.95, "dinnerPrice":15.95, "description": "Eggplant, bamboo shoot, green bean, carrot, bell pepper and basil leaf in coconut red curry sauce with your choice of meat", "notes":""},
    {"name":"Yellow Curry", "price":11.95, "dinnerPrice":15.95, "description": "Potato, carrot, and onion in coconut yellow curry sauce with your choice of meat", "notes":""},
]
const specialties=[
    {"name":"Three Flavor Fish", "price":16.99, "description": "Fried white fish Filet, onion, chopped bell pepper, and three flavor sweet chili sauce", "notes":""},
    {"name":"Crispy Duck", "price":24.99, "description": "Half boneless crispy duck with tangy brown sauce and steamed vegetables", "notes":""},
    {"name":"Seafood Hot Pot", "price":26.99, "description": "Combination of seafood in creamy hot and sour soup with lemongrass, tomato, mushroom, cilantro, basil leaves, and lime juice. Served in hot pot", "notes":""},
    {"name":"Seafood Pad Cha", "price":26.99, "description": "Sautéed shrimp, squid, scallop and mussel with garlic, chili, green peppercorn, in chili basil sauce", "notes":""},
    {"name":"Pla Tod Gra Tiem", "price":18.99, "description": "Fried fish topped with fried garlic and side of steamed vegetables, Served with spicy chili lime sauce", "notes":"Garlic Fish"},
    {"name":"Hot Basil Fish", "price":16.99, "description": "Fried white fish Filet with carrot, bell pepper, and mushroom in chili basil sauce", "notes":""},
]
const soups=[
    {"name":"Tom Yum", "price":6.99, "description": "Traditional Thai hot and sour -infused broth, with mushroom, tomato, and cilantro and your choice of meat", "notes":"w/ Shrimp +$1.00"},
    {"name":"Tom Kha", "price":6.99, "description": "Rich and creamy coconut -infused broth, with mushroom, tomato, cilantro, scallion, and your choice of meat", "notes":"w/ Shrimp +$1.00"},
    {"name":"Miso Soup", "price":5, "description": "Soft tofu, seaweed, green onion in miso broth", "notes":""},
    {"name":"Wonton Soup", "price":5.99, "description": "Pork filled wonton with scallion and cilantro", "notes":""},
    {"name":"Veggie Tofu Soup", "price":5, "description": "Assorted vegetable and tofu in vegetable broth", "notes":""},
]
const saladsnspicysalads=[
    {"name":"House Salad", "price":6.99, "description": "Mixed romaine and iceberg lettuce, cucumber, tomato, served with Ginger Dressing", "notes":""},
    {"name":"Seaweed Salad", "price":6.99, "description": "Japanese style marinated seaweed salad", "notes":""},
    {"name":"Green Papaya Salad", "price":10.99, "description": "Shredded unripe papaya, carrot, green bean, tomato, and roasted peanut in spicy garlic lime sauce", "notes":"Som Tum"},
    {"name":"Larb", "price":13.99, "description": "Ground pork or Chicken with fresh mint, onion, scallion, lemongrass, and mixed with lime sauce. Fried shallot on top", "notes":""},
    {"name":"Num Tok", "price":14.99, "description": "Grilled pork or beef tossed with chill, mint, onion, scallion, dry chill and mixed with lime sauce", "notes":""},
]
const dessert=[
    {"name":"Fried Banana", "price":5.95, "description": "Served with Vanilla Ice cream", "notes":""},
    {"name":"Mango Sticky Rice", "price":6.95, "description": "", "notes":""},
    {"name":"Thai Coconut milk ice cream", "price":6.95, "description": "Home-made Thai Coconut ice cream with peanut on top", "notes":""},
]

const beverages=[
    {"name":"Hot tea / Coffee / Iced Tea", "price":3.95, "description": "", "notes":""},
    {"name":"Coke Products", "price":2.89, "description": "Coke, Diet Coke, Sprite, Lemonade", "notes":""},
    {"name":"Thai Iced Tea / Thai Iced Coffee", "price":3.95, "description": "", "notes":"w/ Boba + $1.00"}
]
const config = {
    groups,
    appetizers,
    noodle,
    noodlesoup,
    wokentree,
    friedrice,
    specialties,
    curry,
    soups,
    saladsnspicysalads,
    dessert,
    beverages,
};

export default config;