import React from 'react';  
  
class CustomImage extends React.Component {  
    render() {  
        let image_path = '';  
        try {  
            image_path = require('../images/' + this.props.src); 
        } catch(err){
            image_path = require('../images/noimage.jfif');
        }
        return (
            <img width={this.props.width} src={image_path} alt={this.props.alt} />  
        );
    }
}

export default CustomImage;